<script>
  import LindenmeyerSystem from "./LindenmeyerSystem/LindenmeyerSystem.svelte";

  let angle = 145;
  let ruleApplications = 1;
</script>

<LindenmeyerSystem {angle} {ruleApplications} />
<label class="fr mr5 mt0">
  <p class="f2 mb0 mt0">θ</p>
  <input class="mr1" type="range" min="0" max="360" bind:value={angle} />
</label>

<style>
  label {
    background-color: var(--secondary-color-lighter);
    padding: 0.5rem;
    border-radius: 0.3rem;
  }
</style>
