import { vec2 } from "gl-matrix";

export function Pose(position, direction) {
  return {
    position: position || vec2.create(),
    direction: direction || vec2.fromValues(1, 0),
  };
}

export function move(magnitude, pose) {
  let movementVector = vec2.create();
  vec2.scale(movementVector, pose.direction, magnitude);

  let newPosition = vec2.create();
  vec2.add(newPosition, pose.position, movementVector);

  return Pose(newPosition, vec2.clone(pose.direction));
}

export function turn(angle, pose) {
  const origin = vec2.fromValues(0, 0);

  let newDirection = vec2.clone(pose.direction);
  vec2.rotate(newDirection, newDirection, origin, angle);

  return Pose(vec2.clone(pose.position), newDirection);
}
