<script>
  import { onMount } from "svelte";

  // Modified version of BooDooPerson's title card generator.
  // Source: https://codepen.io/BooDoo/pen/BRPBoG

  // API
  export let topText = "NEON";
  export let midText = "GENESIS";
  export let botText = "EVANGELION";
  export let epText = "EPISODE:1";
  export let titleText = "ANGEL ATTACK";
  // one of ['serif', 'sans']
  export let titleStyle = "serif";
  // one of ['left', 'center', 'right'] (or x value)
  export let titleAlign = "left";

  const titleStyles = {
    sans: {
      weight: 800,
      family: `Helvetica Neue,Helvetica,sans-serif`,
      squash: 0.8,
    },
    serif: {
      weight: 600,
      family: `Times New Roman,serif`,
      squash: 0.76,
    },
  };

  const canvasWidth = 900;
  const canvasHeight = 675;
  const leftMargin = 75;
  const rightBoundary = 815;

  const smHeadSize = canvasHeight * 0.184;
  const lgHeadSize = canvasHeight * 0.308;
  const epSize = canvasHeight * 0.095;
  const titleSize = canvasHeight * 0.095;

  let canvas;
  let ctx;

  /**
   * @author BooDooPerson
   * @param ctx
   * @param text
   * @param y
   * @param squash
   * @param align
   * @param maxWidth
   */
  function addFittedText(
    ctx,
    text,
    y,
    squash = 1,
    align = "left",
    maxWidth = 740
  ) {
    let x;
    switch (align) {
      case "right":
        ctx.textAlign = "right";
        x = rightBoundary;
        break;
      case "left":
        ctx.textAlign = "left";
        x = leftMargin;
        break;
      case "center":
        ctx.textAlign = "center";
        x = (rightBoundary + leftMargin) / 2;
        break;
      default:
        x = align;
    }

    let toDraw = text.split("\n");
    if (toDraw.length > 1) {
      ctx.textBaseline = "middle";
    }
    for (let n in toDraw) {
      let mWidth = ctx.measureText("M").width;
      // Use maximum available space (if set)
      // or squashed width by ratio
      let widthCalc = ctx.measureText(toDraw[n]).width;
      if (widthCalc * squash >= maxWidth) {
        widthCalc = maxWidth;
      } else {
        widthCalc = widthCalc * squash;
      }
      // we're not stroking text for now, weird artifacts.
      // ctx.strokeText(toDraw[n], x, y+(n*mWidth), widthCalc);
      ctx.fillText(toDraw[n], x, y + n * mWidth, widthCalc);
    }

    // reset to "reasonable" defaults
    ctx.textBaseline = "top";
    ctx.textAlign = "left";
  }

  function draw() {
    ctx = canvas.getContext("2d");

    // these all squash/condense the text horizontally
    const topSquash = 0.62;
    const midSquash = 0.62;
    const botSquash = 0.57;
    const epSquash = 0.76;
    // @Ben this isn't used anywhere?
    //const titleSquash = 0.76;

    // @Ben I think this line applies ctx to addFittedText() for convenience.
    // The resulting function, addText(), works just like addFittedText() but no
    // longer needs to have ctx explicitly passed to it.
    const addText = addFittedText.bind(null, ctx);

    // black background
    // standard @ 900*675
    // wide @ 1280*720
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    ctx.fillStyle = "#000000";
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // Drawing white text with white stroke
    ctx.fillStyle = "#FFFFFF";
    ctx.strokeStyle = "#FFFFFF";
    ctx.textBaseline = "top";
    // Top two lines
    ctx.font = `900 ${smHeadSize}px Times New Roman`;
    addText(topText, 50, topSquash);
    addText(midText, 150, midSquash);
    // Bigger third line
    ctx.font = `900 ${lgHeadSize}px Times New Roman`;
    addText(botText, 239, botSquash);

    // Change font for EPISODE: label
    ctx.font = `700 ${epSize}px Helvetica Neue,Helvetica,sans-serif`;
    addText(epText, 430, epSquash);

    // Change font for Title
    const activeTitleStyle = titleStyles[titleStyle];
    ctx.font = `${activeTitleStyle.weight} ${titleSize}px ${activeTitleStyle.family}`;
    addText(titleText, 530, activeTitleStyle.squash, titleAlign);
  }

  function clearCanvas(ctx) {
    // https://github.com/fserb/canvas2D/blob/master/spec/reset.md#rationale
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();
  }

  // Re-draw whenever props change.
  $: {
    // Props must be referenced in this block for Svelte to trigger it.
    topText &&
      midText &&
      botText &&
      epText &&
      titleText &&
      titleStyle &&
      titleAlign;
    if (ctx) {
      clearCanvas(ctx);
    }
    if (canvas) {
      draw();
    }
  }

  onMount(() => {
    draw();
  });

</script>

<canvas bind:this={canvas} />

<style>
  canvas {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 900px;
    max-height: 675px;
  }

</style>
