<script>
  import { onMount } from "svelte";
  import * as d3 from "d3";
  import { commandsToPositions } from "./utilities";

  export let axiom = "F+F+F+F";
  export let rule = "F+F-F-FF+F+F-F";
  export let ruleApplications = 1;
  export let angle = 145;

  let width = 500;
  let height = width;

  const evalutate = input => input.replace(/F/g, rule);

  let evaluated = axiom;

  // Apply the replacement rule.
  $: {
    let newEval = axiom;
    for (let i = 0; i < ruleApplications; i++) {
      newEval = evalutate(newEval);
    }
    evaluated = newEval;
  }

  // Re-draw whenever props change.
  $: {
    // Props must be referenced in this block for Svelte to trigger it.
    axiom && rule && ruleApplications && angle;
    d3.select(svgEl)
      .selectAll("*")
      .remove();
    draw();
  }

  let svgEl;
  function draw() {
    const svg = d3.select(svgEl);

    let pointPath = commandsToPositions(evaluated, angle);

    const xExtent = d3.extent(pointPath, d => d[0]);
    const xScale = d3
      .scaleLinear()
      .domain(xExtent)
      .range([0, width]);

    const yExtent = d3.extent(pointPath, d => d[1]);
    const yScale = d3
      .scaleLinear()
      .domain(yExtent)
      .range([0, height]);

    const line = d3
      .line()
      .x(d => xScale(d[0]))
      .y(d => yScale(d[1]));

    const path = svg
      .append("path")
      .datum(pointPath)
      .attr("stroke", "var(--primary-color)")
      .attr("fill", "var(--primary-color)")
      .attr("stroke-width", 1.5)
      .attr("d", line);

    svg.on("click", () => {
      // Animate path drawing with stroke-dasharray trick
      // https://stackoverflow.com/a/13354478/6591491
      const totalLength = path.node().getTotalLength();
      path
        .attr("stroke-dasharray", totalLength + " " + totalLength)
        .attr("stroke-dashoffset", totalLength)
        .attr("fill", "var(--secondary-color)")
        .transition()
        .duration(5000)
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0)
        // fill
        .transition()
        .duration(0)
        .attr("fill", "var(--primary-color)");
    });
  }

  onMount(() => {
    draw();
  });
</script>

<style>
  svg {
    width: 100%;
    height: 80%;
  }
</style>

<svg bind:this={svgEl} id="centerpieceDisplay" viewBox="0 0 {width} {height}" />
