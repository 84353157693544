<script>
  import Spinner from "./Spinner.svelte";

  export let imgUrl;
  export let imgAlt;

  export let demoUrl;
  export let gitUrl;

  export let title;
  export let description;

  // Possible states:
  //  "preview": The preview image is shown.
  //  "loading": The iframe has been added, but has not finished loading.
  //  "running": The iframe has loaded.
  let state = "preview";

  function handleDemoClick() {
    switch (state) {
      case "preview":
        document.body.setAttribute("style", "cursor: wait");
        state = "loading";
        break;
      case "loading":
        // Do nothing.
        break;
      case "running":
        state = "preview";
        break;
      default:
        console.warn(`DemoFrame is in an unknown state: ${state}`);
        break;
    }
  }

  function handleIframeLoad() {
    switch (state) {
      case "preview":
        // Do nothing (ignore the load event).
        break;
      case "loading":
        document.body.setAttribute("style", "");
        state = "running";
        break;
      case "running":
        console.warn("iframe has loaded and found DemoFrame already running.");
        break;
      default:
        console.warn(`DemoFrame is in an unknown state: ${state}`);
        break;
    }
  }
</script>

<div class="ma3">
  {#if state === "preview"}
    <div class="fixed-ratio" style="padding-bottom: calc(100%/(1280/720))">
      <picture>
        <source type="image/webp" srcset={`${imgUrl}.webp`} />
        <source type="image/png" srcset={`${imgUrl}.png`} />
        <img class="br2 fixed-ratio-content" alt={imgAlt} src={imgUrl} />
      </picture>
    </div>
  {:else if state === "loading"}
    <div class="fixed-ratio" style="padding-bottom: calc(100%/(1280/720))">
      <Spinner />
      <iframe
        class="ba2 br2 fixed-ratio-content"
        on:load={handleIframeLoad}
        title="A demo"
        src={demoUrl}
        hidden
        style="display: none;"
        allowvr="yes"
        allowfullscreen="yes"
      />
      <picture style="display: none" hidden>
        <source type="image/webp" srcset={`${imgUrl}.webp`} />
        <source type="image/png" srcset={`${imgUrl}.png`} />
        <img
          class="br2 fixed-ratio-content"
          alt={imgAlt}
          src={imgUrl}
          hidden
          style="display: none"
        />
      </picture>
    </div>
  {:else}
    <div class="fixed-ratio" style="padding-bottom: calc(100%/(1280/720))">
      <iframe
        class="ba2 br2 fixed-ratio-content"
        onload={handleIframeLoad}
        title="A demo"
        src={demoUrl}
        allowvr="yes"
        allowfullscreen="yes"
      />
      <picture style="display: none" hidden>
        <source type="image/webp" srcset={`${imgUrl}.webp`} />
        <source type="image/png" srcset={`${imgUrl}.png`} />
        <img
          class="br2 fixed-ratio-content"
          alt={imgAlt}
          src={imgUrl}
          hidden
          style="display: none"
        />
      </picture>
    </div>
  {/if}
  <h2 class="lh-title ttc f3 mt3 mb2">{title}</h2>
  <p class="lh-copy f4 mt0 mb0">{description}</p>
  <p
    style="cursor: pointer"
    on:click={handleDemoClick}
    class="pButton f5 link br3 ph3 pv2 mb2 dib"
  >
    Demo
  </p>
  <a class="pButton f5 link br3 ph3 pv2 mb2 dib" href={gitUrl}>GitHub</a>
</div>

<style>
  div {
    background-color: var(--secondary-color-lighter);
    border-radius: 0.3rem;
  }

  iframe {
    border-style: none;
  }

  .pButton {
    color: var(--secondary-color);
    background-color: var(--primary-color);
  }
  .pButton:hover {
    color: var(--accent-color);
  }

  /* Padding-bottom hack to create responsive elements that don't reflow.
https://www.voorhoede.nl/en/blog/say-no-to-image-reflow/ */
  .fixed-ratio {
    display: block;
    position: relative;
    height: 0;
    overflow: hidden;
  }
  .fixed-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: inherit;
  }
</style>
