<script>
  import NavLink from "./NavLink.svelte";

  export let label = "Blog";
  export let direction = "→";
  export let path = undefined;
  export let url = undefined;
</script>

{#if path}
  <NavLink to={path}>
    <div class="br2 br--right">
      <p class="lh-copy f4 f4-ns">{label}</p>
      <p class="lh-copy f3 f3-ns">{direction}</p>
    </div>
  </NavLink>
{:else}
  <a href={url} class="link">
    <div class="br2 br--right">
      <p class="lh-copy f4 f4-ns">{label}</p>
      <p class="lh-copy f3 f3-ns">{direction}</p>
    </div>
  </a>
{/if}

<style>
  div {
    width: 7rem;
    background-color: var(--primary-color);

    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-auto-rows: 2.5rem;
    grid-gap: 10px;
    align-items: center;
    justify-content: space-evenly;
  }

  div:hover > p {
    color: var(--accent-color);
  }

  div > p {
    color: var(--secondary-color);
  }

  @media (min-width: 500px) {
    div {
      width: 8rem;

      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-auto-rows: 3rem;
      grid-gap: 10px;
      align-items: center;
      justify-content: space-evenly;
    }
  }
</style>
