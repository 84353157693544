<script>
  import * as Sentry from "@sentry/browser";
  import { Integrations } from "@sentry/tracing";
  import { Router, Route } from "svelte-routing";
  import Home from "./routes/home/Home.svelte";
  import About from "./routes/About.svelte";
  import NotFound from "./routes/NotFound.svelte";

  // Used for SSR. A falsy value is ignored by the Router.
  export let url = "";

  // 100vh is broken on mobile browsers [0]. tl;dr; 100vh does not actually add
  // up to 100% of the visible screen on mobile, it's more like 110% as it
  // includes the URL bar. This is super annoying as I want to control the exact
  // layout that gets displayed on page load.
  //
  // The workaround below creates a CSS variable "ivh" that contains the innerHeight
  // in pixels. This value can be used as a stand-in for vh units.
  //
  // ---
  // 0: https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
  document.documentElement.style.setProperty(
    "--ivh",
    `${window.innerHeight}px`
  );

  Sentry.init({
    dsn:
      "https://e610ca99a9d2478fb78974f7cf04bad4@o260768.ingest.sentry.io/5267043",

    // To set your release version
    release: "pyrikdev@v1",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

</script>

<Router {url}>
  <Route path="/about" component={About} />
  <Route path="/" component={Home} />
  <Route component={NotFound} />
</Router>
