<script>
  // TODO: refactor this junk.
  // On wide screens (tablets, laptops, desktops...etc), I want the nav always
  // shown. On narrow screens (phones), I want to replace the nav with a collapsable
  // version.

  let windowWidth = window.innerWidth;

  let open = window.localStorage.getItem("navOpen");
  if (open === null) {
    // Nav has not been opened or closed before, so default to being closed.
    open = false;
    window.localStorage.setItem("navOpen", open);
  } else {
    // Parse stringified boolean to true boolean.
    open = open === "true";
  }

  const toggleOpen = () => {
    open = !open;
    window.localStorage.setItem("navOpen", open);
  };
</script>

<style>
  .nav {
    display: grid;
    grid-gap: 10px;
    position: absolute;

    /* Blur background behind nav and add padding to better handle overlapping
    content. https://github.com/bryik/pyrik.dev/issues/8 */
    background-color: rgb(var(--secondary-color-lighter) / 33%);
    backdrop-filter: blur(10px);
    /* prefix for Safari */
    -webkit-backdrop-filter: blur(10px);
    border-radius: 0.25rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .menu-button > svg {
    color: var(--primary-color);
  }
  .menu-button > svg:hover {
    color: var(--accent-color);
  }
</style>

<svelte:window bind:innerWidth={windowWidth} />

{#if windowWidth > 500}
  <div class="nav">
    <slot />
  </div>
{:else if open}
  <div class="nav">
    <!-- The close button -->
    <div
      class="menu-button h2 w2 dib ma3 mb0"
      style="cursor: pointer"
      on:click={toggleOpen}>
      <svg
        width="33"
        height="23"
        viewBox="0 0 33 23"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="2.46464"
          y="0.901834"
          width="35"
          height="3.5"
          rx="0.75"
          transform="rotate(31.346 2.46464 0.901834)"
          fill="currentColor" />
        <rect
          x="0.763397"
          y="19.3531"
          width="35"
          height="3.5"
          rx="0.75"
          transform="rotate(-32.2315 0.763397 19.3531)"
          fill="currentColor" />
      </svg>
    </div>
    <slot />
  </div>
{:else}
  <div class="nav">
    <!-- The open button -->
    <div
      class="menu-button h2 w2 dib ma3 mb0"
      style="cursor: pointer"
      on:click={toggleOpen}>
      <svg
        width="35"
        height="21"
        viewBox="0 0 35 21"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="35" height="3.5" rx="0.75" fill="currentColor" />
        <rect y="8.75" width="35" height="3.5" rx="0.75" fill="currentColor" />
        <rect y="17.5" width="35" height="3.5" rx="0.75" fill="currentColor" />
      </svg>
    </div>
    <div hidden>
      <slot />
    </div>
  </div>
{/if}
