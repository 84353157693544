<script>
  export let title;
  export let description;
  export let imgUrl;
  export let imgAlt;
  export let slidesUrl;
</script>

<div class="ma3">
  <picture style="grid-area: p">
    <source type="image/webp" srcset={`${imgUrl}.webp`} />
    <source type="image/png" srcset={`${imgUrl}.png`} />
    <img class="br2" alt={imgAlt} src={imgUrl} />
  </picture>

  <h2 class="lh-title ttc f3 mt3 mb2 ml3-ns" style="grid-area: t">{title}</h2>
  <p class="lh-copy f4 mt0 mb3 ml3-ns" style="grid-area: d">{description}</p>
  <a
    class="pButton f5 link br3 ph3 pv2 mb2 dib"
    style="grid-area: l; width: fit-content; height: fit-content; justify-self:
    right"
    href={slidesUrl}
  >
    Slides
  </a>
</div>

<style>
  div {
    background-color: var(--secondary-color-lighter);
    border-radius: 0.3rem;
  }

  .pButton {
    color: var(--secondary-color);
    background-color: var(--primary-color);
  }
  .pButton:hover {
    color: var(--accent-color);
  }

  @media screen and (min-width: 500px) {
    div {
      display: grid;
      grid-template-areas:
        "p t"
        "p d"
        "p l";
      margin: 1rem;
    }
  }
</style>
