<script>
  import Title from "../../components/Title.svelte";
  import NavButton from "../../components/NavButton.svelte";
  import DemoFrame from "../../components/DemoFrame.svelte";
  import Presentation from "../../components/Presentation.svelte";
  import Centerpiece from "./components/Centerpiece/Centerpiece.svelte";
  import Socials from "../../components/Socials.svelte";
  import Navigation from "../../components/Navigation.svelte";

  export let location;
</script>

<main>
  <div class="title mr3">
    <Title />
  </div>

  <div class="navigation">
    <Navigation>
      <NavButton path="/about" label="About" />
      <NavButton url="https://blog.pyrik.dev" label="Blog" direction="←" />
      <NavButton url="#projectList" label="Projects" direction="↓" />
    </Navigation>
  </div>

  <div class="centerpiece">
    <Centerpiece />
  </div>

  <div class="socials">
    <Socials />
  </div>

  <div class="projects">
    <h1 id="projectList" class="lh-title f1 ml3 ml0-l">Projects</h1>
    <DemoFrame
      imgUrl="/assets/noctis"
      imgAlt="Computer generated Martian terrain."
      demoUrl="https://bryik.github.io/aframe-terrain-model-component/Examples/Noctis/"
      gitUrl="https://github.com/bryik/aframe-terrain-model-component"
      title="Noctis Labyrinthus"
      description="A 3D visualization of Mars; built with Three.js."
    />
    <br />
    <DemoFrame
      imgUrl="/assets/yokohama"
      imgAlt="One side of a cubemap."
      demoUrl="https://aframe-cubemap-component.netlify.app/examples/yokohama/index.html"
      gitUrl="https://github.com/bryik/aframe-cubemap-component"
      title="Yokohama at night"
      description="An A-Frame component for rendering cubemaps."
    />
  </div>

  <div class="presentations">
    <h1 id="presentationList" class="lh-title f1 ml3 ml0-l">Presentations</h1>
    <Presentation
      imgUrl="/assets/localizing-react-apps"
      imgAlt="A slide from the presentation. React code depicting the
      useTranslation() hook."
      slidesUrl="https://drive.google.com/file/d/1XsR9yKHIn5lwsvy66YAf7Q01fqn6fPGV/view?usp=sharing"
      title="Localizing React Apps (2020)"
      description="A tech talk I gave at Corteva; describes the basics of
      writing web apps that support multiple languages."
    />
    <br />
    <Presentation
      imgUrl="/assets/summer-in-the-metaverse"
      imgAlt="The first slide."
      slidesUrl="https://docs.google.com/presentation/d/1-X9L8uA7xnjH4jRu3hknnITXqmV0rhQJU7KVT3_OeYk/edit?usp=sharing"
      title="Summer in the Metaverse (2016)"
      description="A lightning talk I gave at Google's Seattle office in the
      summer of 2016 about my GSoC project."
    />
  </div>
</main>

<footer class="pv4 ph3 ph5-m ph6-l mid-gray">
  <small class="f6 db tc">
    © 2020
    <b class="ttu">Ben Pyrik</b>
  </small>
</footer>

<style>
  main {
    display: grid;
    grid-template-areas:
      "n t"
      "c c"
      "s s"
      "p p"
      "pr pr";
  }

  .title {
    grid-area: t;
    align-self: center;
    justify-self: center;
    /* height: 20vh; */
    height: calc(var(--ivh) * 0.2);

    display: grid;
    align-content: center;
    justify-content: center;
  }

  .navigation {
    grid-area: n;
    justify-self: left;
    padding-top: 1rem;
    min-width: 25vw;
  }

  .centerpiece {
    grid-area: c;
    width: 100%;
    /* height: 70vh; */
    height: calc(var(--ivh) * 0.7);
    cursor: pointer;
  }

  .socials {
    grid-area: s;
    /* height: 10vh; */
    height: calc(var(--ivh) * 0.1);

    display: grid;
    align-content: center;
    justify-content: center;
  }

  .projects {
    grid-area: p;
    max-width: 960px;
    width: 100%;
    align-self: center;
    justify-self: center;
  }

  .presentations {
    grid-area: pr;
    max-width: 960px;
    width: 100%;
    align-self: center;
    justify-self: center;
  }
</style>
