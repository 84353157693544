<script>
  export let title = "pyrik.dev";
  export let subtitle = undefined;
  let theme = window.localStorage.getItem("theme");

  if (theme === null) {
    // No theme has been set.
    theme = "light";
    window.localStorage.setItem("theme", "light");
  } else {
    if (theme === "dark") {
      // The default theme is light, so swap to dark.
      document.body.classList.replace(`light-theme`, `dark-theme`);
    }
  }

  function toggleTheme() {
    const oldTheme = theme;
    const newTheme = theme === "light" ? "dark" : "light";

    // Update
    theme = newTheme;
    window.localStorage.setItem("theme", newTheme);
    document.body.classList.replace(`${oldTheme}-theme`, `${newTheme}-theme`);
  }
</script>

<div>
  <h1 class="lh-title f1 f-subheadline-ns mh0 mv0" style="cursor: pointer" on:click={toggleTheme}>{title}</h1>
  {#if subtitle}
    <h2 class="f3 f2-m f1-l lh-copy link mv0 ml4 normal">{subtitle}</h2>
  {/if}
</div>
