import { Pose, move, turn } from "./Pose";

export function degToRad(angle) {
  return angle * (Math.PI / 180);
}

export function commandsToPositions(input, angle) {
  const tokens = input.split("");

  let path = [];
  let current = Pose();

  tokens.forEach((token) => {
    switch (token) {
      case "F":
        current = move(1, current);
        path.push(current.position);
        break;
      case "+":
        current = turn(-degToRad(angle), current);
        break;
      case "-":
        current = turn(degToRad(angle), current);
        break;
    }
  });

  return path;
}
