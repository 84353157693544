<script>
  import EvaTitleCard from "../components/EvaTitleCard.svelte";

  export let location = "";

  const topText = "404";
  const midText = "ERROR";
  const botText = "FILE NOT FOUND";
  const epText = location.pathname;
  const titleText = "The resource can (not) be located.";
  const titleStyle = "serif";
  const titleAlign = "left";

  // Quick hack to change <body>'s color and background.
  document.body.setAttribute("style", "color: white; background-color: black;");

</script>

<EvaTitleCard
  {topText}
  {midText}
  {botText}
  {epText}
  {titleText}
  {titleStyle}
  {titleAlign}
/>

<div class="footer">
  <a href="/">RUN AWAY</a>
</div>

<style>
  .footer {
    position: fixed;
    bottom: 0;
  }

  .footer > a {
    color: var(--accent-color);
    display: block;
    margin-left: 1em;
    margin-bottom: 1em;
    font-size: larger;
  }

</style>
